<template>
  <div
    class="transferView"
    v-loading="loading"
    element-loading-spinner="el-icon-loading"
    element-loading-background="#FFFFFF"
    v-scroll="{
      distance: 100,
      onBottom: _onBottom,
    }"
  >
    <div class="success" v-if="successLoading">
      <el-image
        style="width: 100%;"
        :src="require('../../../../assets/images/im/notice/success.png')"
        :fit="'fill'"
      ></el-image>
    </div>
    <div class="blockContent">
      <template v-for="(item, index) in listData">
        <div class="transferContent" v-if="item.isMember" :key="index">
          <div class="date">{{ item.updateTime }}</div>
          <div class="text">
            {{ item.showText }}
          </div>
        </div>
        <div class="transferContent" v-if="item.isOwner" :key="index">
          <div class="date">{{ item.updateTime }}</div>
          <div class="text" :class="{ disabledText: item.disabled }">
            {{ item.showText }}
          </div>
          <div class="footer">
            <div
              class="button confirm"
              v-waves
              :class="{ disabled: item.disabled }"
              @click.stop="approvedOrRejected(item, 1)"
            >
              <i class="iconfont_Me icon-check iconStyle"></i>
            </div>
            <div
              class="button"
              v-waves
              :class="{ disabled: item.disabled }"
              @click.stop="approvedOrRejected(item, -1)"
            >
              <i class="iconfont_Me icon-x iconStyle"></i>
            </div>
          </div>
        </div>
      </template>
    </div>
    <el-dialog
      :visible.sync="approvedTransferVisible"
      width="313px"
      :append-to-body="true"
      :modal-append-to-body="true"
      :close-on-click-modal="false"
      :top="'0'"
      :custom-class="'approvedTransferDialog'"
    >
      <div class="dialogContent">
        <div class="text" v-if="selectedItem">
          {{ $t("transferConfirmTips") }} - {{ selectedItem.groupName }}
        </div>
        <div class="button">
          <div class="item" v-waves @click.stop="approved">
            <i class="iconfont_Me icon-check iconStyle"></i>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import * as IMApi from "@/api/im";
export default {
  inject: ["control"],
  data() {
    return {
      page: 1,
      rawData: [],
      loading: false,
      successLoading: false,
      successTimer: null,
      approvedTransferVisible: false,
      selectedItem: null,
      selectedStatus: 0,
    };
  },
  mounted() {
    this.selectedItem = null;
    this.selectedStatus = 0;
    this.getApplicationList();
  },
  beforeDestroy() {
    this.selectedItem = null;
    this.selectedStatus = 0;
    if (this.successTimer) {
      clearTimeout(this.successTimer);
    }
  },
  computed: {
    // 当前登录的用户ID
    userId() {
      return this.$store.getters.userInfo.id;
    },
    listData() {
      const data = this.rawData.map((data) => {
        // 如果当前登录用户是申请发送者
        if (this.userId == data.memberId) {
          data.isMember = true;
          // 已拒绝
          if (data.status == -1) {
            data.showText = `${data.groupOwnerName} ${this.$t(
              "rejectedYourGroupPermissionApplication"
            )} - ${data.groupName}`;
            // 已同意
          } else if (data.status == 1) {
            data.showText = `${data.groupOwnerName} ${this.$t(
              "approvedYourGroupPermissionApplication"
            )} - ${data.groupName}`;
            // 冻结直接转让或者群主主动转让
          } else if (data.status == 2 || data.status == 3) {
            data.showText = `${this.$t("obtainedGroupPermission")} - ${
              data.groupName
            }`;
          } else {
            data.showText = JSON.stringify(data.status);
          }
          // 如果当前登录用户是群主
        } else if (this.userId == data.groupOwnerId) {
          data.isOwner = true;
          // 如果当前登录用户还是当前群主则有权限对通知进行
          if (this.userId == data.currentGroupOwnerId) {
            data.disabled = false;
          } else {
            data.disabled = true;
          }
          switch (data.status) {
            case 0:
              data.showText = `${data.memberName} ${this.$t(
                "appliedHostControls"
              )} - ${data.groupName}`;
              break;
            case -1:
              data.disabled = true;
              data.showText = `${this.$t("refused")} - ${
                data.memberName
              } ${this.$t("appliedHostControls")} - ${data.groupName}`;
              break;
            case -2:
              data.disabled = true;
              data.showText = `${this.$t("refused")} - ${
                data.memberName
              } ${this.$t("appliedHostControls")} - ${data.groupName}`;
              break;
            default:
              data.disabled = true;
              data.showText = `${this.$t("agreed")} - ${
                data.memberName
              } ${this.$t("appliedHostControls")} - ${data.groupName}`;
              break;
          }
          // 如果申请用户状态是冻结或者被删除，则也不能操作
          if (!data.memberStatus) {
            data.disabled = true;
          }
        }
        return data;
      });
      return data;
    },
  },
  methods: {
    // 通过或者拒绝
    async approvedOrRejected(item, status) {
      this.selectedItem = item;
      this.selectedStatus = status;
      if (this.selectedItem.disabled) {
        return;
      }
      if (this.selectedStatus == 1) {
        this.approvedTransferVisible = true;
        return;
      }
      await this.sendRequest();
    },
    async approved() {
      await this.sendRequest();
      this.approvedTransferVisible = false;
    },
    // 发送处理后请求
    async sendRequest() {
      if (this.selectedItem && this.selectedStatus != 0) {
        const result = await IMApi.auditGroupTransferApplication(
          this.selectedItem.id,
          this.selectedStatus
        );
        if (result.code == 200) {
          if (!result.data.data) {
            this.$message({
              type: "error",
              message: this.$t("operationFailed"),
            });
            return;
          } else {
            this.rawData = this.rawData.map((i) => {
              // 如果是同意转让，则将所有本群的申请置灰；反之只将被操作对象的置灰
              if (this.selectedStatus == 1) {
                if (i.groupId && i.groupId == this.selectedItem.groupId) {
                  if (i.id == this.selectedItem.id) {
                    i.currentGroupOwnerId = this.selectedItem.memberId;
                    i.status = 1;
                  } else {
                    i.currentGroupOwnerId = this.selectedItem.memberId;
                    i.status = -1;
                  }
                }
              } else if (this.selectedStatus == -1) {
                if (i.id == this.selectedItem.id) {
                  i.currentGroupOwnerId = this.selectedItem.memberId;
                  i.status = -1;
                }
              }
              return i;
            });
          }
          this.showSuccess();
        } else {
          this.$message({
            type: "error",
            message: result.message,
          });
        }
      }
    },
    showSuccess() {
      if (this.successTimer) {
        clearTimeout(this.successTimer);
      }
      this.successLoading = true;
      this.successTimer = setTimeout(() => {
        this.successLoading = false;
      }, 3000);
    },
    async getApplicationList() {
      return new Promise(async (resolve, reject) => {
        this.loading = true;
        const result = await IMApi.getGroupTransferApplicationList(this.page);
        this.loading = false;
        if (result.code == 200) {
          if (result.data.data.length != 0) {
            this.rawData = this.rawData.concat(result.data.data);
            resolve();
          } else {
            resolve();
          }
        } else {
          this.$message({
            type: "error",
            message: result.message,
          });
          reject();
        }
      });
    },
    _onBottom() {
      this.page++;
      this.getApplicationList()
        .then(() => {})
        .catch(() => {
          this.page--;
        });
    },
  },
};
</script>
<style lang="stylus" scoped>
@import '~styles/utils/index';
.transferView
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-light();
  background: #FFFFFF;
  .success
    width: 45px;
    overflow: hidden;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate3d(-50%,-50%,0);
    z-index: 1;
  .blockContent
    width: 100%;
    overflow: hidden;
    background: #F7F7F7
    .transferContent
      width: 100%;
      min-height: 50px;
      background: #FFFFFF;
      box-sizing: border-box;
      padding: 10px 15px;
      margin-bottom: 10px;
      .date
        width: 100%;
        font-size: 10px;
        height: 20px;
        line-height: 20px;
        color: #999999;
        text-align: left;
      .text
        width: 100%;
        font-size: 12px;
        color: #000000;
        min-height: 30px;
        line-height: 30px;
        display: -webkit-box;
        -webkit-line-clamp: 2; // 设置两行文字溢出
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      .disabledText
        color: #D9D9D9 !important;
      .footer
        width: 100%;
        height: 25px;
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        .disabled
          cursor: not-allowed !important;
          background: #D9D9D9 !important;
        .button
          height: 100%;
          width: 85px;
          background: #A1A4A9;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          .iconStyle
            font-size: 14px;
            color: #FFFFFF;
        .confirm
          margin-right: 30px;
          background: #66C871;
</style>

<style lang="stylus">
.approvedTransferDialog
  .el-dialog__header
    padding: 0;
  .el-dialog__body
    padding: 0;
    width: 100%;
    height: 191px;
    .dialogContent
      width: 100%;
      height: 100%;
      .text
        width: 100%;
        box-sizing: border-box;
        padding: 55px 20px 0 20px;
        color: #000000;
        user-select none;
        display: -webkit-box;
        word-break: break-all;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        font-size: 14px;
        line-height: 20px;
        overflow: hidden;
      .button
        width: 100%;
        height: 27px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 45px;
        .item
          height: 100%
          width: 114px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 14px;
          background: #52BD68;
          cursor: pointer;
          .iconStyle
            color: #FFFFFF;
            font-size: 16px;
</style>
